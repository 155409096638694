module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-scroll-indicator/gatsby-browser.js'),
      options: {"plugins":[],"color":"#D2C7A1","height":"3px","paths":["/*"],"zIndex":9999},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@seeka-labs/gatsby-plugin-seeka/gatsby-browser.js'),
      options: {"plugins":[],"publicKey":"sdkr_Q2ZESjhLcDYtaE1EUWlCQXB3Y0FZTXZ3TjNURVNlWlB4QlNWVlo2Wk5ZOEVQbll5Xy13T3hTVGxpRWI1NWJ5V0VFUmhsZEl1aFJsWkM5Y0xINWlaMnNPbTA3TXdUV0pEdzRBTU1HazdPVXNFUDdlSjk5SnhPUER1dkY4d0JCamhGTlpuVHVYSlYwT3JjUHhHcEk5MktuTGtmLWNZS1JWS0tZMFVUSFFYTjI0TThYSFo","org":"43e7cd2adf0a94af51f93a0d5c49e64c","id":"0348d879b61b19c25ae93a0d5c4a46a7","hosts":[{"hostname":"22c7.artworks.com.sg"},{"hostname":"eb73.artworks.com"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
